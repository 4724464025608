<template>
  <p class="clearfix mb-0 mt-25">
    <span
      class="float-md-left d-block d-md-inline-block"
      v-html="$t('general.footer', { currentYear: new Date().getFullYear() })"
    >
    </span>
    <span class="ml-1">
      <b-link href="https://facerecognition.io/terms/" target="_blank">
        {{ $t("footer.terms-of-use") }}
      </b-link>
      -
      <b-link href="https://facerecognition.io/privacy/" target="_blank">
        {{ $t("footer.privacy-policy") }}
      </b-link>
    </span>
  </p>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
